.photos-container {
  /* height: 400px;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.photos-style {
  height: 350px;
  width: 100%;
  /* max-width: 400px; */
  max-height: 400px;
  /* object-fit: contain; */
}
